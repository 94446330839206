import React, { useEffect } from 'react';

import css from './meet.module.scss';
import shared from 'styles/shared.module.scss';

const Meet = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '3823430',
          formId: 'ea0377c1-9ae9-42e5-a1b6-6152a5ff8842',
          target: '#hubspotForm',
        });
      }
    });
  }, []);

  return (
    <>
      <section className={`${css.content} ${shared.maxwidth}`}>
        <h2 className={shared.sectionTitle}>
          The first step to prevent food outbreaks and recalls
        </h2>
        <p className={shared.sectionDescription}>Schedule a meeting with us</p>
        <div className={css.form} id="hubspotForm"></div>
      </section>
    </>
  );
};

export default Meet;
